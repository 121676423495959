<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Mortgage Types
        </strong>
        <hwa-button
          variant="primary"
          label="Create Mortgage Type"
          icon="PlusIcon"
          @onClick="openServiceModal()"
        />
      </div>
      <b-table
        small
        :fields="fields"
        :items="services"
        responsive="sm"
        selectable
        select-mode="single"
        @row-selected="handleRowClick"
      >
        <template #cell(body)="data">
          <div v-html="data.item.body" />
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="serviceForm"
      v-slot="{ invalid }"
    >
      <hwa-modal
        :title="`Mortgage Type`"
        :show="serviceModalOpened"
        size="lg"
        hide-footer
        :is_data_change="is_service_change"
        @onClose="closeServiceModal()"
      >
        <validation-provider
          #default="{ errors }"
          name="Title"
          rules="required"
        >
          <hwa-input
            v-model="service.title"
            name="title"
            label="Title"
            placeholder="Enter title"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <div class="row">
          <div class="col-12">
            <hwa-text-area
              v-model="service.body"
              label="Description"
            />
          </div>
        </div>
        <div
          class="mt-3"
          style="display:flex;justify-content:space-between;"
        >
          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            :class="is_service_change ? 'active-secondary' : ''"
            label="Cancel"
            @onClick="closeServiceModal()"
          />
          <hwa-button
            v-if="updateServiceMode"
            variant="danger"
            icon="XIcon"
            class="mr-1"
            label="Delete"
            @onClick="deleteService()"
          />

          <hwa-button
            variant="success"
            :label="updateServiceMode ? 'Update' : 'Create'"
            :class="is_service_change === true ? 'active-success' : ''"
            :disable="invalid || is_service_change === false ? true : false"
            :loading="serviceLoading"
            loading-text="Loading..."
            @onClick="updateServiceMode ? updateService() : createService()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import {
  BTable, BCard, BBadge, BFormGroup, BFormRadioGroup, BAvatar, BRow,
} from 'bootstrap-vue'
import HwaFileButton from '@/layouts/components/HwaFileButton.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import HwaTextArea from '@/layouts/components/HwaTextArea.vue'

export default {
  components: {
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BCard,
    BBadge,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormRadioGroup,
    quillEditor,
    BAvatar,
    HwaFileButton,
    BRow,
    HwaTextArea,
  },
  mixins: [showToast],
  data() {
    return {
      fields: [{ key: 'title', label: 'Title', sortable: true }, 'body'],
      services: [],
      title: '',
      service_id: null,
      serviceLoading: false,
      updateServiceMode: false,
      serviceModalOpened: false,
      selectedService: {},
      service: {
        id: null,
        title: '',
        body: '',
      },
      is_service_change: false,
    }
  },
  watch: {
    service: {
      handler() {
        this.is_service_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getServices()
  },
  methods: {
    openServiceModal() {
      this.updateServiceMode = false
      this.serviceModalOpened = true
      this.is_service_change = false
    },
    emptyData() {
      this.service = {
        id: null,
        title: '',
        body: '',
      }
      const dom = this
      setTimeout(() => {
        dom.is_service_change = false
      }, 100)
    },
    closeServiceModal() {
      if (this.is_service_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.serviceModalOpened = false
            this.is_service_change = false
            this.updateService()
          } else if (result.dismiss === 'cancel') {
            this.serviceModalOpened = false
            this.is_service_change = false
            this.emptyData()
          }
        })
      } else {
        this.serviceModalOpened = false
        this.is_service_change = false
      }
    },
    createService() {
      this.is_service_change = false
      this.serviceLoading = true
      this.$http.post('/api/services', this.service)
        .then(res => {
          this.serviceLoading = false
          this.services = [res.data, ...this.services]
          this.showSuccess('Created Successfully')
          this.service.title = ''
          this.service.body = ''
          this.closeServiceModal()
        })
        .catch(err => {
          this.serviceLoading = false
          this.showError('Failed to update services')
          this.closeServiceModal()
        })
    },
    updateService() {
      this.serviceLoading = true
      this.$http.put(`/api/services/${this.service.id}`, this.service)
        .then(res => {
          this.serviceLoading = false
          this.services = this.services.map(c => {
            if (c.id === res.data.id) {
              return res.data
            }
            return c
          })
          this.showSuccess('Updated Successfully')
          this.serviceModalOpened = false
          this.is_service_change = false
          const dom = this
          setTimeout(() => {
            dom.emptyData()
          }, 100)
        })
        .catch(err => {
          this.serviceLoading = false
          this.showError('Failed to update services')
        })
    },
    handleRowClick(row) {
      if (row['0']) {
        this.service = { ...row['0'] }
        this.updateServiceMode = true
        this.serviceModalOpened = true
        const dom = this
        setTimeout(() => {
          dom.is_service_change = false
        }, 100)
      }
    },
    getServices() {
      this.$http.get('/api/services')
        .then(res => {
          this.services = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve services')
        })
    },
    deleteService() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/api/services/${this.service.id}`)
            .then(res => {
              this.services = this.services.filter(p => p.id !== this.service.id)
              this.serviceModalOpened = false
              this.updateServiceMode = false
            })
            .catch(err => {
              this.showError('Failed to retrieve services')
            })
        }
      })
    },
  },
}
</script>
